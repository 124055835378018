<template>
  <section class="reg" ref="a" v-if="registerInfoForm.status==1" :style="{'min-height':clientWidth/375*724+'px','background-image': 'url('+registerInfoForm.loginBackgroundImgUrl+')'}">
    <div class="logo">
      <!-- <img src="../../../assets/images/h5-logo-2x.png" /> -->
      <div class="fr" @click="changeRouterRule()">活动规则</div>
    </div>
    <!-- <div class="text">
      <img src="../../../assets/images/title-2x.png" class="title_image" />
      <img src="../../../assets/images/font-little-title-2x.png" class="font-tite_image" />
    </div> -->
    <div class="reg-form">
      <input type="tel" v-model="mobile" maxlength="11" placeholder="请输入手机号"/>
      <div class="code-content">
        <input type="text" v-model="code" placeholder="请输入验证码" />
        <button class="get-code" :disabled="codeBtnStatus" @click="getCode()">
          {{ codeText }}
        </button>
      </div>
      <div class="error-tips" v-if="errorInfo">
          <img src="../../../assets/images/icon-jingshix-2x.png" alt="" />
          <span>
            {{ errorInfo }}
          </span>
      </div>
      <img :src="registerInfoForm.loginButtonImgUrl" class="reg-btn" :disabled="subBtnStatus" @click="changeRouterSuccess" />
      <!-- <button
        class="reg-btn"
        :disabled="subBtnStatus"
        @click="changeRouterSuccess()"
      >
        立即领取
      </button> -->
    </div>
  </section>
  <section class="reg" :style="{'min-height':clientHeight+'px'}" v-else>
    <div class="container">
      <img src="@/assets/images/none@2x.png">
      <p class="title">{{title}}</p>
      <p class="describe">{{describe}}</p>
    </div>
  </section>

</template>

<script>
import { Toast } from "vant";
import { register, sendSms, registerInfo, getSignature, configPageClk } from "@/api/coupon";
import { isMobile } from "@/utils/validate";
import { initTalkingData } from "@/utils/talkingdata";


(function (win, doc) {
  function changeSize() {
    doc.documentElement.style.fontSize = doc.documentElement.clientWidth / 3.75 + 'px';
  }
  changeSize();
  win.addEventListener('resize', changeSize, false);
})(window, document)
export default {
  data() {
    return {
      count: 60,
      codeText: "获取验证",
      codeBtnStatus: false,
      subBtnStatus: true,
      errorInfo: "",
      mobile: "",
      code: "",
      adcode: "",
      title: '',
      describe: '',
      registerInfoForm: {
        uuid: "",
        actUuid: "",
        actNo: "",
        activityQuota: 0,
        loginBackgroundImgUrl: '',
        loginButtonImgUrl: '',
        status: 1,
        ticketStatus: undefined
      },
      clientHeight: document.documentElement.clientHeight,
      clientWidth:document.documentElement.clientWidth
    };
  },
  components: {},
  watch: {
    mobile: function (newVal) {
      if (newVal) {
        this.errorInfo = "";
      }
      if (newVal && this.code) {
        this.subBtnStatus = false;
      } else {
        this.subBtnStatus = true;
      }
    },
    code: function (newVal) {
      if (newVal) {
        this.errorInfo = "";
      }
      if (newVal && this.mobile) {
        this.subBtnStatus = false;
      } else {
        this.subBtnStatus = true;
      }
    },
  },
  mounted() {
    this.$store.commit("loading/HIDELOADING");
    this.getRegisterInfo()
    this.getAdCode();
    initTalkingData()
  },
  methods: {
    getRegisterInfo() {
      let ticketUuid = this.$route.query.ticketUuid;
      const that = this
      if(ticketUuid) {
        registerInfo({ ticketUuid }).then(async (res)=>{
          if(res.data){
            this.registerInfoForm = res.data
            switch (res.data.status) {
              case 0:
                this.title = '抱歉，该活动暂未开始。'
                this.describe = '活动还未开始哦，请晚些再来~'
                break
              case 2:
                this.title = '抱歉，该活动已结束'
                this.describe = '抱歉，您来晚了，活动已经结束了哦～'
                break
            }
            await this.getShare()
            
            
            wx.ready(function() {
              var title = res.data.shareTitle || document.title;
              var myurl = window.location.href;
              wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                  var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                  var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                  var speed = res.speed; // 速度，以米/每秒计
                  var accuracy = res.accuracy; // 位置精度
                  that.getAdCode(longitude, latitude)
                }
              });
              //分享给朋友
              wx.onMenuShareAppMessage({
                title : title, // 分享标题
                desc : res.data.shareDesc, // 分享描述
                link : myurl, // 分享链接
                imgUrl : res.data.shareImgUrl, // 分享图标
                dataUrl: '',
                success : function() {
                    // 用户确认分享后执行的回调函数
                },
                cancel : function() {
                    // 用户取消分享后执行的回调函数
                }
              });

              wx.onMenuShareQQ({
                  title : title, // 分享标题
                  link : myurl, // 分享链接
                  imgUrl : res.data.shareImgUrl, // 分享图标
                  success : function() {
                      // 用户确认分享后执行的回调函数
                  },
                  cancel : function() {
                      // 用户取消分享后执行的回调函数
                  }
              });

            });

            wx.error(function(res) {
              // alert(res.errMsg);
            });
          }
        })
      }
    },
    getShare() {
      let url = window.location.href
      getSignature({url}).then(res=>{
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.appId, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature,// 必填，签名
          jsApiList: ["updateAppMessageShareData", "onMenuShareAppMessage", "onMenuShareQQ", "getLocation"] // 必填，需要使用的JS接口列表
        });
      })
    },
    getCode() {
      this.errorInfo = "";

      if (!this.mobile) {
        this.errorInfo = "请输入手机号";
        return;
      }

      if (!isMobile(this.mobile)) {
        this.errorInfo = "请输入您正确的手机号";
        return;
      }
      let ticketUuid = this.$route.query.ticketUuid;
      this.codeBtnStatus = true;
      Toast.loading({
        duration: 0,
        message: "获取中...",
      });

      sendSms({
        // appid: "c543eae4aa0a49fdab3ed761f6345001",
        ticketUuid: ticketUuid,
        phone: this.mobile,
      }).then(
        (res) => {
          Toast.clear();
          if (res.returnCode === 10000) {
            this.cutdown();
          } else {
            this.codeBtnStatus = false;
            Toast(res.msg);
          }
        },
        () => {
          this.codeBtnStatus = false;
          Toast.clear();
        }
      );
    },
    transformAdcode(lng, lat){
      if(lng && lat) {
        const that = this
        var map = new AMap.Map('Geocoder');
        map.plugin('AMap.Geocoder', function() {
          var geocoder = new AMap.Geocoder({
            // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
            city: '010'
          })
          var lnglat = [lng, lat]
          // 使用geocoder做地理/逆地理编码
          geocoder.getAddress(lnglat, function(status, result) {
            if (status === 'complete' && result.info === 'OK') {
                // result为对应的地理位置详细信息
              that.adcode = result.regeocode.addressComponent.adcode
            }
          })
        })
      }
    },
    getAdCode(lng, lat) {
      const that = this
      // var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
      // if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //   //在微信中打开
      //   this.transformAdcode(lng, lat)
      // }else{
    
        var geolocation;
        geolocation = new AMap.Geolocation()
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete",
          function onComplete(data) {
            that.adcode = data.addressComponent.adcode;
          }
        ); 
        AMap.event.addListener(geolocation, "error",
          function onError(data) {
            that.getBMapPosition()
          }
        ); 
        //返回定位信息
      // }
    },
    /**
      * 百度地图获取经纬度
    */
    getBMapPosition() {
      const that = this;
      const BMapGeolocation = new BMap.Geolocation();
      BMapGeolocation.getCurrentPosition((r) => {
        if (r.latitude && r.longitude) {
          that.transformAdcode(r.longitude, r.latitude)
        }
      });
    },
    cutdown() {
      let cutdownTimeout = setTimeout(() => {
        this.count--;
        if (this.count >= 0) {
          this.codeText = `重新获取(${this.count}S)`;
          this.cutdown();
        } else {
          clearTimeout(cutdownTimeout);
          this.codeText = `获取验证码`;
          this.codeBtnStatus = false;
          this.count = 60;
        }
      }, 1000);
    },
    changeRouterRule() {
      this.$router.push({
        path: "/coupon-rule",
        query: {
          id: this.$route.query.ticketUuid,
        },
      });
      // Dialog.alert({
      //   title: '活动规则',
      //   message: this.registerInfoForm.activityRuleUrl,
      //   confirmButtonColor: '#8e8e8e'
      // })
    },
    changeRouterSuccess() {
      let ticketUuid = this.$route.query.ticketUuid;

      var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
      if(!this.adcode){
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          Toast("您已拒绝微信访问位置，请使用浏览器扫码访问。");
        }else if(/wxwork/i.test(ua)){
          Toast("请使用浏览器扫码访问。");
        }else{
          Toast("请打开位置权限，否则无法参与活动。");
        }
        return false
      }


      if (!this.mobile) {
        this.errorInfo = "请输入手机号";
        return;
      }
      if (!this.code) {
        this.errorInfo = "请输入验证码";
        return;
      }
      
      register({
        adcode: this.adcode,
        phone: this.mobile,
        code: this.code,
        ticketUuid: ticketUuid,
      }).then((res) => {
        // res = {"msg":"抱歉，该活动不对该城市开放~","returnTime":1648189203819,"success":false,"returnCode":90000,"data":null}
        if (res.returnCode === 10000) {
          window.location.href = `/coupon-success?mobile=${this.mobile}&ticketUuid=${ticketUuid}`;
        } else {
          if(res.msg === '验证码错误！') {
            Toast(res.msg);
          }else{
            this.registerInfoForm.status = 3
            this.title = res.msg
            // this.$router.push({
            //   path: "/coupon-error",
            //   query: {
            //     msg: res.msg,
            //   },
            // });
          }
        }
      });

      configPageClk({
        uuid: ticketUuid
      })
    },
  },
};
</script>

<style scoped lang="scss">
.reg {
  position: relative;
  width: 100%;
  height: 100%;
  // background: #1f1f21 url("../../../assets/images/h5-bg-2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow-y: scroll;
  .container{
    margin-top: 25%;
    text-align: center;
  }
  img{
    width: 54%;
    height: 130px;
  }
  .title{
    color: #999999;
    font-size: 24px;
    line-height: 33px;
    font-weight: 500;
  }
  .describe{
    color: #999999;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
  }

  .logo {
    margin: 0.16rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      width: 1rem;
      height: 0.32rem;
    }
    .fr {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    color: #000000;
      line-height: 20px;
    }
  }

  .text {
    text-align: center;

    .title_image {
      width: 52%;
      margin-bottom: 26px;
    }
    .font-tite_image {
      width: 100%;
    }
  }
  .reg-form {
    position: absolute;
    top: 58%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
      input {
        padding: 0 0.2rem;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 22px;
        width: 3.15rem;
        height: 0.54rem;
        background: #ffffff;
        border-radius: 8px;
        border: none;
        box-sizing: border-box;
        margin-bottom: 0.16rem;
      }
      .code-content{
        position: relative;
        display: flex;
        .get-code {
          position: absolute;
          right: 0;
          font-size: 0.16rem;
          font-weight: 400;
          color: #222;
          border: none;
          width: 1.2rem;
          background: #EAEAEA;
          color: #222222;
          height: 0.54rem;
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
          opacity: 1;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    .error-tips {
      margin: 5px auto 5px;
      width: 315px;
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: #8e6d5b;
      height: 20px;
      line-height: 20px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: middle;
      }
      span {
        line-height: 18px;
      }
    }
    .reg-btn {
      margin-top: 0.08rem;
      width: 3.15rem;
      height: 0.5rem;
      // font-size: 16px;
      // font-family: PingFangSC-Regular, PingFang SC;
      // font-weight: 400;
      // color: #ffffff;
      // line-height: 22px;
      // background: url("../../../assets/images/h5-btn-2x.png") no-repeat;
      // background-size: cover;
      // border: none;
      &:disabled {
        opacity: 0.5;
      }
    }
    .submit{
      font-size: 0.16rem;
      width: 3.15rem;
      height: 0.5rem;
      color: #ffffff;
    }
  }
}
input::-webkit-input-placeholder {
  /* WebKit browsers*/
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000;
  opacity: 0.5;
}
input:-moz-input-placeholder {
  /* Mozilla Firefox 4 to 18*/
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
}
input::-moz-input-placeholder {
  /* Mozilla Firefox 19+*/
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+*/
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  opacity: 0.5;
}
</style>