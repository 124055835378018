/**
 * 初始化TD脚本插入
 */
export function initTalkingData() {
    var htmm =document.getElementsByTagName("html")[0];
    var scri = document.createElement("script");
    window.document.title = ''
    scri.async="async"
    scri.src="https://jic.talkingdata.com/app/h5/v1?appid=062AE5D176D54C669BDE53180D1440F4"
    htmm.appendChild(scri);
}
