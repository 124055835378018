import request from '@/global/xhr'

// 获取券内活动信息
export function registerInfo(data) {
  return request({
    url: '/api/h5/ticket/registerInfo',
    method: 'get',
    params: data
  })
}

// 发送验证码
export function sendSms(data) {
  return request({
    url: '/api/h5/ticket/sendSms',
    method: 'get',
    params: data
  })
}

// 领取优惠券
export function register(data) {
  return request({
    url: '/api/h5/ticket/register',
    method: 'get',
    params: data
  })
}
// 查询优惠券
export function getCoupon(data) {
  return request({
    url: '/api/h5/ticket/user/unread/coupon',
    method: 'get',
    params: data
  })
}
// 微信分享
export function getSignature(data) {
  return request({
    url: '/api/h5/ticket/getSignature',
    method: 'get',
    params: data
  })
}
// 活动配置按钮点击
export function configPageClk(data) {
  return request({
    url: '/api/h5/ticket/count/configPage/clk',
    method: 'get',
    params: data
  })
}

// 券类活动活动数据查看
export function getStaticInfo(data) {
  return request({
    url: '/api/h5/ticket/dataTicket.ajax',
    method: 'get',
    params: data
  })
}

// 用户领券记录
export function getPassRecord(data) {
  return request({
    url: '/api/h5/ticket/dataTicket/pass/record',
    method: 'get',
    params: data
  })
}

