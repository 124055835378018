/**
 * @param {string} arg
 * @returns {Boolean}
 */
 export function isMobile(arg) {
    var reNum = /^1[3|4|5|6|7|8|9][0-9]{9}$/; 
   return(reNum.test(arg))
}

// 固定电话
/**
 * @param {string} arg 
 * @returns {Boolean}
 */
 export function isgdMobile(arg) {
    var reNum = /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
    return (reNum.test(arg))
  }


/**
 * @param {string}{date} arg
 * @returns {string}
 */
export function formatTime(fmt,target){
	//fmt为日期格式，target为转化的时间
	var o = {  
        "M+": target.getMonth() + 1, //月份   
        "d+": target.getDate(), //日   
        "H+": target.getHours(), //小时   
        "m+": target.getMinutes(), //分   
        "s+": target.getSeconds(), //秒   
        "q+": Math.floor((target.getMonth() + 3) / 3), //季度   
        "S": target.getMilliseconds() //毫秒   
    };  
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (target.getFullYear() + "").substr(4 - RegExp.$1.length));  
    for (var k in o)  
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));  
    return fmt;  
}
 